<template>
  <footer class="footer">
    <div class="container">
      <div class="container-search">
        <input type="text" placeholder="Поиск новостей..." />
      </div>

      <div class="container-categories">
        <div class="links">
          <router-link class="category" to="/">Главная</router-link>
        </div>
        <div class="links">
          <router-link
            class="category"
            :to="{ name: 'home', params: { type: 'Здоровье' } }"
            >Здоровье</router-link
          >
        </div>
        <div class="links">
          <router-link
            class="category"
            :to="{ name: 'home', params: { type: 'Развлечения' } }"
            >Развлечения</router-link
          >
        </div>
        <div class="links">
          <router-link
            class="category"
            :to="{ name: 'home', params: { type: 'Путешествия' } }"
            >Путешествия</router-link
          >
        </div>
        <div class="links">
          <router-link
            class="category"
            :to="{ name: 'home', params: { type: 'Юмор' } }"
            >Юмор</router-link
          >
        </div>

        <router-link to="/"
          ><span class="logo"
            ><span>FOX</span><span>READ</span></span
          ></router-link
        >
      </div>

      <div class="container-info">&copy; 2018-2023</div>
    </div>
  </footer>
</template>
<script></script>
