<template>
  <HeaderComp />
  <router-view />
  <FooterComp />
</template>
<script>
import HeaderComp from "@/components/layouts/HeaderComp.vue";
import FooterComp from "@/components/layouts/FooterComp.vue";
import "@/scss/main.scss";

export default {
  components: {
    HeaderComp,
    FooterComp,
  },
};
</script>
