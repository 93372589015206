import ApiModel from './ApiModel'

class Auth extends ApiModel {
    auth(data) {
        return this.post(`${this.baseUrl}/auth`, data);
    }
    reg(data) {
        return this.post(`${this.baseUrl}/reg`, data);
    }
}

export default new Auth('/auth');