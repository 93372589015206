import Api from "@/api/news";
import { useRoute } from "vue-router";
import { ref } from "vue";

export default function news() {
  let data = ref({});

  const getNews = async () => {
    const route = useRoute();
    let response = await Api.getModel(route.params.id);
    data.value = response.data;
    document.title = `${data.value.title} | Foxread`;
  };

  return {
    data, getNews
  };
}
