import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'

const routes = [
  {
    meta: {
      title: 'Foxread - электронный журнал',
    },
    path: '/:type?',
    name: 'home',
    component: HomeView
  },
  {
    meta: {
      title: 'Новости',
    },
    path: '/news/:id',
    name: 'news',
    component: NewsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let pageTitle = to.meta.title ? `${to.meta.title} | ` : '';
  document.title = `${pageTitle} Foxread`;
  next();
});

export default router
