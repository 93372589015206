import Api from "@/api/news";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default function main() {
  let mainNews = ref({ id: 1, title_image: "" });
  let topNews = ref({});
  let otherNews = ref({});
  let router = useRoute();
  const mainUrl = "https://backend.foxread.ru/";

  const getNews = async () => {
    let type = "";

    if (router.params.type !== "undefined") {
      type = router.params.type;
    }

    let response = await Api.getModels({ type: type });
    const news = response.data.otherNews;
    mainNews.value = response.data.mainNews;
    otherNews.value = news.slice(3);
    topNews.value = news.slice(0, 3);
  };

  // getNews();

  // onMounted(() => {
  //   getNews();
  // })

  watch(router, () => {
    getNews();
  });

  return {
    mainNews,
    mainUrl,
    topNews,
    otherNews,
    getNews,
  };
}
