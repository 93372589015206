import Api from "@/api/auth";
import { ref } from "vue";
import store from '@/store'

export default function main() {
  let email = ref("");
  let password = ref("");
  let name = ref("");
  let login = ref("");

  const auth = async () => {
    const response = await Api.auth({ email: email.value, password: password.value });
    if (response.data !== null) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      store.commit("setToken", response.data.token);
      store.commit("setUser", response.data.user);
      // router.push("/news/");
    } else {
      alert("bad password");
    }
  };


  const reg = async () => {
    const response = await Api.reg({ email: email.value, password: password.value, login: login.value });
    if (response.data !== null) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      store.commit("setToken", response.data.token);
      store.commit("setUser", response.data.user);
    } else {
      alert("bad password");
    }
  };

  return {
    auth,
    email,
    password,
    name,
    login,
    reg,
  };
}
