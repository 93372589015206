<template>
  <header class="header">
    <div class="container">
      <span class="menu" @click="handleMenu"></span>
      <router-link to="/"
        ><span class="logo"
          ><span>FOX</span><span>READ</span></span
        ></router-link
      >
      <div class="nav">
        <router-link to="/">Главная</router-link>
        <router-link :to="{ name: 'home', params: { type: 'Здоровье' } }"
          >Здоровье</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Развлечения' } }"
          >Развлечения</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Путешествия' } }"
          >Путешествия</router-link
        >
        <router-link :to="{ name: 'home', params: { type: 'Юмор' } }"
          >Юмор</router-link
        >
      </div>
      <span class="search"></span>
      <div class="login">
        <!-- @click="showAuth = true" -->
        <span v-if="!user">Войти</span>
        <div class="user" v-else>
          <img :src="baseUrl + 'storage/images/' + user.avatar" />
          <span>{{ user.email }}</span>
        </div>
      </div>
    </div>
    <div v-if="openMenu" class="moduleMenu">
      <router-link to="/">Главная</router-link>
      <router-link :to="{ name: 'home', params: { type: 'Здоровье' } }"
        >Здоровье</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Развлечения' } }"
        >Развлечения</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Путешествия' } }"
        >Путешествия</router-link
      >
      <router-link :to="{ name: 'home', params: { type: 'Юмор' } }"
        >Юмор</router-link
      >
    </div>

    <AuthComp v-if="showAuth" />
  </header>
</template>
<script>
import AuthComp from "@/components/AuthComp.vue";

export default {
  name: "HeaderComp",
  setup() {},
  data() {
    return {
      openMenu: false,
      showAuth: false,
      baseUrl: process.env.VUE_APP_API,
    };
  },
  components: {
    AuthComp,
  },
  computed: {
    user() {
      if (this.$store.state.user != "undefined") {
        return JSON.parse(this.$store.state.user);
      } else {
        return "";
      }
    },
  },
  methods: {
    handleMenu() {
      if (this.openMenu === false) {
        this.openMenu = true;
      } else {
        this.openMenu = false;
      }
    },
  },
};
</script>
