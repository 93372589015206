<template>
  <div class="AuthComp">
    <div class="body">
      <div v-if="!regOpen" class="authForm">
        <input type="text" v-model="email" placeholder="Электронная почта" />
        <input type="password" v-model="password" placeholder="Пароль" />
        <button @click="auth">Войти</button>
        <span class="reg" @click="regOpen = true">Регистрация</span>
      </div>
      <div v-else class="regForm">
        <input type="login" v-model="login" placeholder="Логин" />
        <input type="text" v-model="email" placeholder="Электронная почта" />
        <input type="password" v-model="password" placeholder="Пароль" />
        <button @click="reg">Зарегистрироваться</button>
      </div>
    </div>
  </div>
</template>
<script>
import authComp from "@/composables/views/auth.ts";

export default {
  setup() {
    const { auth, email, password, login, reg } = authComp();
    return { auth, email, password, login, reg };
  },
  name: "AuthComp",
  data() {
    return {
      regOpen: false,
    };
  },
  methods: {},
};
</script>