<template>
  <div class="news container">
    <div class="preloader" v-if="preloader"><div class="loader"></div></div>
    <div class="content">
      <h1>{{ data.title }}</h1>
      <span class="content-text" v-html="data.text"></span>
    </div>
    <div class="news-info"><b>Автор: </b> {{ data.authorName }}</div>
  </div>
</template>

<script>
import news from "@/composables/views/news.ts";
// import { useRouter } from "vue-router";

export default {
  name: "NewsView",
  setup() {
    const { data, getNews } = news();
    return { data, getNews };
  },
  data() {
    return {
      preloader: true,
    };
  },
  async created() {
    await this.getNews();
    this.preloader = false;
  },
};
</script>
