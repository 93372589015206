<template>
  <div class="container home">
    <div class="preloader" v-if="preloader"><div class="loader"></div></div>
    <div class="row">
      <div class="col-12 col-xl-4" v-for="news in topNews" :key="news.id">
        <router-link :to="{ name: 'news', params: { id: news.id } }">
          <div class="categoryNews">
            <img
              class="titleImg"
              :src="mainUrl + 'storage/images/' + news.title_image"
            />
            <p>{{ news.title }}</p>
            <span class="date">{{
              new Date(news.created_at * 1000).toLocaleDateString("ru")
            }}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <router-link :to="{ name: 'news', params: { id: mainNews.id } }">
          <div class="mainNews">
            <img
              class="mainNews-img"
              :src="mainUrl + 'storage/images/' + mainNews.title_image"
            />
            <div class="mainNews-info">
              <span>1/3</span>
              <h2>{{ mainNews.title }}</h2>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-xl-4" v-for="news in otherNews" :key="news.id">
        <router-link :to="{ name: 'news', params: { id: news.id } }">
          <div class="categoryNews">
            <img
              class="titleImg"
              :src="mainUrl + 'storage/images/' + news.title_image"
            />
            <p>{{ news.title }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import main from "@/composables/views/main.ts";

// @ is an alias to /src
export default {
  name: "HomeView",
  setup() {
    const { mainNews, mainUrl, topNews, otherNews, getNews } = main();
    return { mainNews, mainUrl, topNews, otherNews, getNews };
  },
  data() {
    return {
      preloader: true,
    };
  },
  async created() {
    await this.getNews();
    this.preloader = false;
  },
};
</script>
